export default {
  responseLab: "https://responselab.stanford.edu/",
  oneYoungWorld: "https://www.oneyoungworld.com/counsellors",
  xoor: "https://xoor.io/en/",
  sherlock: "https://www.sherlockcomms.com/",
  stanford: "http://h4r.stanford.edu/",
  siena: "https://sienacompany.com/",
  cccadv: "http://cccadv.com.br/site/theoffice/?lang=en",
  curative: "https://curative.com/",
  fenwick: "https://www.fenwick.com/",
  nuvu: "https://www.nuvu.cc/",
  parrolabs: "https://www.parrolabs.com/",
  devlights: "https://www.devlights.com/",
  arkangel: "https://arkangel.ai/en",
  pillsbury: "https://www.pillsburylaw.com/en/",
  aws: "https://aws.amazon.com/",
  aws2:
    "https://aws.amazon.com/government-education/nonprofits/disaster-response/diagnostic-dev-initiative/",
  littler: "https://www.littler.com/",
  nuevaEPS: "https://www.nuevaeps.com.co/",
  spi: "https://spiamericas.com/en/home/",
  idimi: "https://idime.com.co/",
  cj: "https://www.cj-partners.com/",
  serviceBots: "https://www.servicebots.net/sas.html",
  lockedJar: "https://www.lockedjar.com/",
  Cleveland: "https://clevelandibnsina.com/",
  drive: "https://drive.hhs.gov/",
  dgaAbogados: "https://col-law.com/home-2/",
  olartemoure: "https://www.olartemoure.com/",
  lswInstitute: "https://www.lswinstitute.org/",
  barda: "https://www.medicalcountermeasures.gov/barda",
  stanfordMed: "https://med.stanford.edu/",
  jigJP: "https://www.jig.jp/",
  shil: "https://innovations.stanford.edu/home",
  kvgmedical: "https://www.kvgmch.org/",
  compensar: "https://corporativo.compensar.com/",
  takemotoLab: "http://www.hisac.u-fukui.ac.jp/imlabo/home.html",
  uFukuiJapan: "https://www.u-fukui.ac.jp/",
  uFukuiEnglish: "https://www.u-fukui.ac.jp/eng",
  nexusTaxServices: "https://nexustaxaccountants.com/",
};
